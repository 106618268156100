$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.detailItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid;
  font-size: 1rem;

  &.primary {
    background-color: $white;
    border-color: $secondary-200;
  }

  &.selected {
    background-color: $white;
    border-color: transparent;
    box-shadow: 0 0 0 2px $secondary-900;
  }

  &.error {
    background-color: $error-100;
    border-color: $error-600;
  }

  &.warning {
    background-color: $warning-100;
    border-color: $warning-600;
  }

  &.large {
    padding: 1.25rem 1.5rem;
  }

  &.clickable {
    cursor: pointer;
  }

  .titleContainer {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .subtitle {
      display: block;
      font-size: 0.875rem;
      color: $secondary-600;
    }
  }

  .rightSection {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: auto;
  }
}
