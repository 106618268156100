@import './colours.scss';
@import './defaultTheme.scss';

// App variables
$text-dark: $secondary-900;
$border-color: $secondary-400;
$border-radius-sm: 0.25rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 1.5rem;
$row-indicator-width: 0.75rem;

.tableTitleText {
    font-size: 1rem;
}