$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import '@skiwo/styles/global/fonts.scss';

.calendar {
  font-family: 'Poppins';
  border: 0;

  .react-datepicker__header {
    padding: 1rem 0.75rem 0rem 0.75rem;
    background-color: transparent;
    border: 0;

    .react-datepicker__current-month {
      color: $secondary-700;
      font-size: 0.875rem;
      font-weight: 500;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .react-datepicker__navigation {
    margin: 1.75rem 1rem 0rem 1rem;

    &:hover::before {
      border-color: $secondary-700;
    }

    .react-datepicker__navigation-icon {
      &::before {
        border-color: $secondary-700;
        border-right: 1px $secondary-700 solid;
        border-top: 1px $secondary-700 solid;
      }
    }
  }

  .react-datepicker__day-name {
    color: $secondary-500;
    margin: 0;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .react-datepicker__month {
    .react-datepicker__day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      border-radius: 0;

      &.react-datepicker__day--keyboard-selected {
        background-color: $white;
      }

      &.react-datepicker__day--in-selecting-range,
      &.react-datepicker__day--in-range {
        background-color: $primary-200;
        color: $primary-600;
      }

      &.react-datepicker__day--selected {
        background-color: $primary;
        color: #fff;
        font-weight: 500;
        border-radius: 0.25rem;
      }

      &.react-datepicker__day--selected.react-datepicker__day--range-start,
      &.react-datepicker__day--selected.react-datepicker__day--in-selecting-range {
        background-color: $primary;
        color: #fff;
        font-weight: 500;
        border-radius: 0.25rem 0 0 0.25rem;
      }

      &.react-datepicker__day--selected,
      &.react-datepicker__day--range-start {
        background-color: $primary;
        color: #fff;
        font-weight: 500;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &.react-datepicker__day--range-end {
        background-color: $primary;
        color: #fff;
        font-weight: 500;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &.react-datepicker__day--today:not(.react-datepicker__day--selected):not(
          .react-datepicker__day--range-start
        ):not(.react-datepicker__day--range-end) {
        background-color: $secondary-200;
        border-radius: 0.25rem;
      }

      &.react-datepicker__day--today.react-datepicker__day--in-range:not(
          .react-datepicker__day--range-end
        ) {
        border-radius: 0 !important;
      }

      &.react-datepicker__day--outside-month {
        background-color: $white;
        color: $secondary-300;

        &:empty {
          height: 0;
        }
      }
    }
  }
}
