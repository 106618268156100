$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.badge {
  background-color: $secondary-100;

  span {
    line-height: 24px;
    color: $secondary-500;
  }

  &.disabled {
    background-color: $secondary-300;
  }

  &.active {
    background-color: $primary-100;
    span {
      color: $primary;
    }
  }

  &.default:hover {
    background-color: $secondary-50;
  }

  &.pointer-cursor {
    cursor: pointer;
  }
}
