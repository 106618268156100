$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.content {
  .heading {
    font-weight: 600;
    color: $secondary-900;
    line-height: 2.75rem;
    margin-bottom: 0.75rem;
  }

  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary-600;
  }

  .formHeader {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .checkbox,
    .newDepartment {
      margin-top: 1rem;
    }

    .orgNumber {
      .hint {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 1.5rem;
        color: $secondary-600;
      }
    }

    .referenceInputs {
      display: flex;
      gap: 2rem;

      & > * {
        width: 100%;
      }

      .container {
        margin-top: 0.5rem;
        .icon {
          color: $warning-600;
          margin-right: 0.5rem;
        }
        .warning {
          color: $secondary-700;
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }

    .nextButton {
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }

    .orderNote {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1.5rem;
      color: $secondary-900;
    }
  }

  .estimateContainer {
    margin-top: 1em;
  }

  .blacklistErrorBanner {
    padding-top: 1rem;
  }
}
