$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.container {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  .icon {
    margin: 0.1rem;
    height: 1rem;
    width: 1rem;
  }

  &.error {
    color: $error-600;
  }

  &.warning {
    color: $secondary-600;
    .icon {
      color: $warning-600;
    }
  }
}
