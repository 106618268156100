$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.skeleton {
  .box {
    height: 1.5rem;
    border-radius: $border-radius-sm;
  }

  .details {
    margin-top: 0.25rem;
    height: 1rem;
    border-radius: $border-radius-sm;
  }

  &.circlesContainer {
    display: inline-flex;
    justify-content: center;
    width: 100%;

    .circles {
      display: inline-flex;
      flex-direction: row;
      gap: 0.5rem;

      & > * {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }
    }
  }
}
