$secondary: #101828;
$secondary-50: #fafbfe;
$secondary-100: #f2f4f7;
$secondary-200: #e4e7ec;
$secondary-300: #d0d5dd;
$secondary-400: #98a2b3;
$secondary-500: #667085;
$secondary-600: #475467;
$secondary-700: #344054;
$secondary-800: #1d2939;
$secondary-900: #101828;

$success-100: #f1faf3;
$success-200: #e2f6e8;
$success-300: #c5ecd1;
$success-400: #a8e3b9;
$success-500: #8bd9a2;
$success-600: #6ed08b;
$success-700: #58a66f;
$success-800: #427d53;
$success-900: #2c5338;
$success: $success-700;

$info-100: #eaf4ff;
$info-200: #d5e9fe;
$info-300: #abd3fd;
$info-400: #82bcfc;
$info-500: #58a6fb;
$info-600: #2e90fa;
$info-700: #2573c8;
$info-800: #1c5696;
$info-900: #123a64;
$info: $info-600;

$warning-100: #fffaed;
$warning-200: #fff4db;
$warning-300: #ffe9b7;
$warning-400: #fede93;
$warning-500: #fed36f;
$warning-600: #fec84b;
$warning-700: #cba03c;
$warning-800: #98782d;
$warning-900: #66501e;
$warning: $warning-600;

$error-100: #fbefee;
$error-200: #f8dedd;
$error-300: #f1bebb;
$error-400: #e99d98;
$error-500: #e27d76;
$error-600: #db5c54;
$error-700: #af4a43;
$error-800: #833732;
$error-900: #582522;
$danger: $error-600;

$white: #ffffff;
