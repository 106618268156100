$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.tabs {
  :global(li.nav-item) {
    &:not(:first-of-type) {
      margin-left: 0.75rem;
    }

    button {
      font-weight: 400;
    }
  }

  :global(button.nav-link) {
    position: relative;
    margin-bottom: 1.25rem;

    &:hover {
      background-color: $secondary-100;
    }

    & *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  :global(button.nav-link:not(.active, .disabled)) {
    .icon {
      color: $secondary-700;
    }
  }

  :global(button.nav-link:hover .badge) {
    background-color: $secondary-50;
    span {
      color: $secondary-500;
    }
  }

  .active {
    &::after {
      content: '';
      position: absolute;
      bottom: -1.25rem;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background: $primary;
    }
  }
}
