$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.megaMenu {
  @include media-breakpoint-down(sm) {
    overflow: auto;
  }

  &.showBorder {
    border-bottom: 1px solid $secondary-200;
  }

  .navContainer {
    padding-left: 0;
  }

  .brand {
    display: flex;
    align-items: center;
    max-height: 2.5rem;
  }

  .loggedOutBrand {
    margin-top: 2.25rem;
  }

  :global(.navbar-collapse) {
    display: flex;
  }

  div:global(.navbar-nav) {
    &:last-of-type {
      margin-left: auto;
    }
  }

  .navItem {
    position: relative;
    height: 100%;
    font-weight: 500;

    &:global(.show),
    &.active {
      color: $primary;

      :global(.nav-link) {
        color: $primary;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.25rem;
        background: $primary;
      }
    }

    &:not(:first-of-type) {
      margin-left: 1rem;

      @include media-breakpoint-down(lg) {
        margin-left: 0rem;
      }
    }

    a:global(.nav-link) {
      padding: 1.5rem 0.75rem;
    }

    .tabContent {
      position: absolute;
      display: flex;
      padding: 2rem;
      left: 0;
      margin-top: -1px;
      border-radius: 0.75rem;
      background-color: $white;
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
      max-width: 100vw;
      z-index: 2;

      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
        width: 100vw;
      }

      & > :not(:first-of-type) {
        margin-left: 2.5rem;

        @include media-breakpoint-down(lg) {
          margin-left: 0rem;
          margin-top: 1rem;
        }
      }
    }
  }
}
