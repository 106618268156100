$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.inputGroup {
  .inputContainer {
    position: relative;

    .input {
      &.hasDecorationIcon {
        text-indent: 1.25rem;
      }

      &:global(.is-invalid) {
        background-color: $error-100;
      }

      &::-webkit-search-cancel-button {
        appearance: none;
        height: 1rem;
        width: 1rem;
        border-radius: 50em;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{str-replace('#{$secondary-700}', '#', '%23')}' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z'/%3E%3C/svg%3E")
          no-repeat 50% 50%;
        background-size: contain;
        cursor: pointer;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .decorationIcon {
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0.5rem;
        margin: auto 0;
        color: $secondary-700;
      }
    }

    .decorationView {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      right: 0.5rem;
      margin: auto 0;
    }

    .passwordIcons {
      svg {
        color: $secondary-700;
      }

      .passwordToggle {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0.5rem;
        margin: auto 0;
        cursor: pointer;

        &.capsLockOn {
          right: 1.75rem;
        }
      }

      .capsLock {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0.5rem;
        margin: auto 0;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  small.errorMessage {
    color: $error-600;
    display: block;

    & > :not(:first-child) {
      margin-left: 0.25rem;
    }
  }
}

input[type='number'] {
  appearance: textfield;
}
