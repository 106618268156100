$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.banner {
  color: $text-dark;
  font-size: 0.875rem;

  .title {
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    font-weight: 700;
    color: $secondary-900;
  }

  .body {
    color: $secondary-600;

    &.leftPad {
      margin-left: 1.8rem;
    }

    a {
      color: $text-dark;
    }
  }

  .icon {
    margin-right: 0.625rem;
    font-size: 1rem;

    &.information {
      color: $info-600;
    }
    &.success {
      color: $success-700;
    }
    &.warning {
      color: $warning-600;
    }
    &.error {
      color: $error-600;
    }
  }

  .content {
    display: flex;
    margin-top: 0.75rem;

    & > :not(:first-child) {
      margin-left: 0.75rem;
    }
  }
}
