$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.chip {
  border-radius: $border-radius-lg;
  border: 1px solid $secondary-300;
  color: $secondary-900;
  padding: 0.5rem 0.75rem;

  span {
    font-weight: 400;
  }

  &.selected {
    background-color: $primary;

    span {
      color: white;
    }
  }
}
