$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.container {
  display: grid;
  gap: 1.5rem;

  .title {
    color: $secondary-900;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .subtitle {
    color: $secondary-600;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .section {
    display: grid;
    gap: 0.5rem;

    .header {
      color: $secondary-900;
      font-size: 1rem;
      font-weight: 600;
    }

    .container {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    }

    .list {
      display: flex;
      flex-direction: column;
    }

    .title {
      color: $secondary-600;
      font-size: 1rem;
      font-weight: 400;
      min-width: 35%;
    }

    .value {
      color: $secondary-900;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }

  .divider {
    height: 0.0625rem;
    background-color: $secondary-200;
  }
}
