$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.content {
  .estimateContainer {
    background-color: $white;
    border-radius: 8px;
    padding: 2rem;

    & > :not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .price {
      font-weight: 600;
      font-size: 3rem;
      line-height: 2.5rem;
      color: $secondary-900;
    }

    .title {
      color: $secondary-600;
      font-weight: 400;
      line-height: 1.5rem;
      font-size: 1rem;
    }

    .value {
      color: $secondary-900;
      font-weight: 400;
      font-size: 1rem;
    }

    .icon {
      color: $secondary-700;
      margin-left: 0.25rem;
    }

    .divider {
      background-color: $secondary-200;
      width: 100%;
      height: 0.063rem;
    }
  }
}
