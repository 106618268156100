$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.content {
  .heading {
    font-weight: 600;
    color: $secondary-900;
    line-height: 2.75rem;
    margin-bottom: 0.75rem;
  }

  .getQuoteSection {
    margin-bottom: 2.5rem;

    .heading {
      font-size: 2.25rem;
    }
  }

  .whatYouNeedSection {
    margin-bottom: 1.5rem;

    .heading {
      font-size: 1.5rem;
    }
  }

  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary-600;
  }

  .customerType {
    margin-bottom: 1.5rem;
  }

  .sourceLanguage,
  .targetLanguages {
    margin-bottom: 1.5rem;
  }

  .deadline {
    margin-bottom: 2.5rem;

    .banner {
      margin-top: 1rem;
    }
  }

  .fileUploaderTitle {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .fileUploader {
    margin-bottom: 3rem;

    .fileCountContainer {
      display: inline-flex;
      width: 10rem;
      align-items: center;
    }

    .fileCountText {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left: 0.5rem;
      color: $secondary-600;
    }

    .hint {
      font-size: 0.875rem;
      color: $secondary-600;
      font-weight: 400;
      line-height: 1.25rem;
    }

    .divider {
      display: flex;
      flex-direction: row;
      color: $secondary-600;
      margin: 1.5rem 0;
      font-weight: 400;
    }

    .divider:before,
    .divider:after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
      color: $secondary-200;
    }
    .divider:before {
      margin-right: 1rem;
    }
    .divider:after {
      margin-left: 1rem;
    }

    .banner {
      margin-top: 1.5rem;
    }
  }

  .fileItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .fileItem {
      .previewImage,
      .fileIcon {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: $border-radius-sm;
        object-fit: contain;
      }

      .spinner {
        display: flex;
        align-items: center;
        height: 2rem;
        width: 2rem;
      }

      .fileIcon {
        color: $secondary-100;
      }

      .name {
        font-weight: $font-weight-normal;
        color: $secondary-900;
        line-height: $line-height-sm;
        word-break: break-all;
        font-size: 0.875rem;
      }

      .size {
        color: $secondary-600;
        font-size: 0.875rem;
      }

      .status {
        color: $primary;
        font-weight: $font-weight-normal;
        font-size: 0.875rem;
        line-height: 16px;
      }

      .wordCount {
        display: flex;
        width: 10rem;
        align-items: center;

        .wordCountText {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          color: $secondary-600;
        }
      }

      .notificationContainer {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }

  .nextButton {
    margin-bottom: 1.5rem;
  }

  .orderNote {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    color: $secondary-900;
  }

  .error {
    color: $error-600;
    font-weight: 400;
    font-size: 0.875rem;
  }
}
