$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.fileUploader {
  position: relative;
  border: 1px dashed $secondary-400;
  border-radius: $border-radius-md;
  background-color: $white;
  margin-bottom: 1rem;
  padding: 0 1rem;
  cursor: pointer;

  &.isDragOver {
    background-color: $primary-100;
    border-color: $primary;
  }

  &.disabled {
    background-color: $secondary-300;
    border-color: $secondary-400;
    cursor: not-allowed;
  }

  &.error {
    background-color: $error-100;
    border-color: $error-600;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }

  img {
    height: 4rem;
    width: 4rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
    line-height: 20px;
  }

  .description {
    color: $secondary-900;
    .action {
      color: $primary;
    }
  }

  .hint {
    color: $secondary-600;
    font-size: 0.875rem;
  }
}

.errorMessage {
  color: $error-600;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  .icon {
    margin-right: 0.5rem;
  }
}
