$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.content {
  .getQuoteSection {
    margin-bottom: 2.5rem;
  }

  .whatYouNeedSection {
    margin-bottom: 1.5rem;
  }

  .heading {
    font-weight: 600;
    color: $secondary-900;
    line-height: 2.75rem;
    margin-bottom: 0.75rem;
  }

  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary-600;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .phoneInputs,
    .nameInputs {
      display: flex;
      gap: 2rem;
      align-items: flex-start;

      & > * {
        width: 100%;
      }
    }

    .phoneInputs {
      & > :first-child {
        max-width: 8rem;
      }
    }

    .orgNumber {
      input {
        margin-bottom: 0.25rem;
      }
      .hint {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 1.5rem;
        color: $secondary-600;
      }
    }
  }

  .nextButton {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .orderNote {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    color: $secondary-900;
  }
}
