$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.dateSeparator {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.dateSeparator::before,
.dateSeparator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $secondary-200;
}

.dateSeparator:not(:empty)::before {
  margin-right: 0.5em;
}

.dateSeparator:not(:empty)::after {
  margin-left: 0.5em;
}
