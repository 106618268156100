$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-weight: 500;

  &[disabled] {
    @include button-variant($secondary-300, $secondary-300);
    color: $secondary-500;
  }

  &.fullWidth {
    width: 100%;
  }

  &.small,
  &.medium {
    font-size: 0.875rem;
    span {
      line-height: 0.875rem;
    }
  }

  &.large,
  &.x-large {
    font-size: 1rem;
    span {
      line-height: 1rem;
    }
  }

  .spinner {
    margin-right: 0.5rem;
  }

  .icon {
    &.left {
      margin-right: 0.5rem;
    }

    &.right {
      margin-left: 0.5rem;
    }
  }

  .label {
    width: 100%;
  }

  &.small {
    padding: 0.25rem 0.5rem;

    .icon {
      &.left {
        margin-right: 0.25rem;
      }

      &.right {
        margin-left: 0.25rem;
      }
    }
  }

  &.medium {
    padding: 0.5rem 0.75rem;
  }

  &.large {
    padding: 0.625rem 1.25rem;
  }

  &.x-large {
    padding: 0.875rem 1.25rem;
  }

  .label {
    width: 100%;
  }
}
