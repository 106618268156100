$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.content {
  & > * {
    margin-bottom: 2.5rem;
  }

  .successfulOrderSection {
    display: inline-flex;
    height: 15rem;
    background-color: $secondary-200;
    width: 100%;
    margin-top: 2.5rem;
    border-radius: 8px;

    img {
      height: 5rem;
      margin: 0 auto;
      align-self: center;
      box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.15);
    }
  }

  .heading {
    font-weight: 600;
    color: $secondary-900;
    line-height: 2.75rem;
    margin-bottom: 0.75rem;
  }

  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary-600;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .heading {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $secondary-900;
    }

    .descriptionContainer {
      display: flex;
      flex-direction: column;

      .maxCharacter {
        color: $secondary-600;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-top: 0.5rem;
      }
    }

    .calendarIcon {
      color: $secondary-700;
    }

    .saveButton {
      margin-top: 1.5rem;
    }
  }

  .banner {
    margin-bottom: 1.5rem;
  }

  .loginButton {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .loginButtonSubtitle {
    color: $secondary-900;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}
